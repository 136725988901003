import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/ctadisclosureff.module.scss"
class Ctadisclosureint extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {},language: {}});
  }
  componentDidMount(){
    this.props.data.then(data => {
      this.setState({data: data});
      this.setState({language: this.props.language});
    });
  }
  render() {
    return (
        <div id = {Styles.ctadisclaimer}>
          {(() => {
            switch (this.state.language) {
              case 'de':
                return <p>Diese kostenlose Erweiterung aktualisiert die Sucheinstellungen dieses Browsers. Wenn Sie fortfahren, erkennen Sie die
                  <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Nutzungsbedingungen</a> und die 
                  <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Datenschutzrichtlinie</a> an und erhalten möglicherweise zusätzliche optionale Angebote.</p>;
                break;
                case 'fr':
                return <p>Cette extension gratuite mettra à jour les paramètres de recherche de ce navigateur. En continuant, vous acceptez les 
                  <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>conditions d'utilisation </a> et la 
                  <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>politique de confidentialité</a>, et des offres facultatives supplémentaires peuvent vous être présentées.</p>;
                break;
                case 'br':
                  return <p>Esta extensão gratuita irá atualizar as configurações de busca do seu navegador. Ao continuar, você concorda com a 
                    <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Política de Privacidade</a> e 
                    <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Termos de Uso</a>, e você será potencialmente apresentado a mais ofertas opcionais.</p>;
                  break;
                  case 'italianXpi':
                    return <p>Questa estensione gratuita aggiornerà le impostazioni di ricerca di questo browser. Continuando, accetti i 
                    <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Termini di Servizio</a> e 
                    <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}> l'Informativa sulla Privacy</a> e potrebbero essere presentate ulteriori offerte facoltative.</p>;
                    break;
                  case 'swedishXpi':
                    return <p>Detta gratistillägg kommer att uppdatera webbläsarens sökinställningar. Genom att fortsätta godkänner du 
                    <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Användarvillkoren</a> och 
                    <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}> Sekretesspolicyn</a> och kan komma att presenteras med ytterligare valfria erbjudanden.</p>;
                    break;
                  case 'finnishXpi':
                    return <p> Tämä ilmainen laajennus päivittää tämän selaimen hakuasetukset. Jatkamalla hyväksyt 
                    <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>palveluehdot</a> ja 
                    <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}> tietosuojakäytännön</a>, ja sinulle voidaan esittää valinnaisia lisätarjouksia.</p>;
                    break;
                  case 'norwegianXpi':
                    return <p>Denne gratis utvidelsen vil oppdatere denne nettleserens søkeinnstillinger. Ved å fortsette godtar du 
                    <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Servicevilkår</a> og 
                    <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}> Personvernreglene</a>, og du kan bli presentert for ytterligere valgfrie tilbud.</p>;
                    break;
                  case 'danishXpi':
                    return <p>Denne gratis udvidelse opdaterer denne browsers søgeindstillinger. Ved at fortsætte, anerkender du 
                    <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Vilkårene for Bruk</a> og 
                    <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Privatlivspolitik</a>, og du kan blive præsenteret for yderligere valgfrie tilbud.</p>;
                    break;
                  case 'dutchXpi':
                    return <p>Deze gratis extensie updatet de zoekinstellingen van deze browser. Als u doorgaat, erkent u de 
                    <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Servicevoorwaarden</a> en het 
                    <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Privacybeleid</a>, en krijgt u mogelijk aanvullende optionele aanbiedingen te zien.</p>;
                    break;  
              default:
                return <p>This free extension will update this browser's search settings. By continuing, you acknowledge the 
                  <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Privacy Policy</a> and 
                  <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Terms of Service</a>, and that you may be presented with more optional offers.</p>;
            }
          })()}
        </div>
    )
  }
}
export default Ctadisclosureint

